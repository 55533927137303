export enum LoanQuoteStatus {
  ApplicationRegisteredPendingBankDecision = '311 Pending Bank Decision',
  ApplicationRegisteredPendingBankDecisionValidation = '312 Pending Bank Decision - Validation',
  ApplicationRegisteredPendingBankDecisionFormSubmit = '313 Pending Bank Decision - Form Submit',
  ApplicationRegisteredPendingBankDecisionTaxConsent = '314 Pending Bank Decision - eTax Consent',
  ApplicationRegisteredPendingBankDecisionPSD2Consent = '315 Pending Bank Decision - PSD2 Consent',
  ApplicationRegisteredPendingBankDecisionDocuments = '316 Pending Bank Decision - Documents',
  ApplicationRegisteredPendingBankDecisionInformation = '317 Pending Bank Decision - Information',
  ApplicationRegisteredPendingBankDecisionCoApplicant = '318 Pending Bank Decision - Co-Applicant',
  ApplicationRegisteredPendingBankDecisionSignature = '319 Pending Bank Decision - Signature',
  ApplicationRegisteredPendingBankDecisionBank = '349 Pending Bank Decision - Bank',
  ApplicationRegisteredApprovedByBank = '351 Approved by Bank',
  ApplicationRegisteredNotApprovedByBank = '361 Not Approved by Bank',
  ApplicationRegisteredNotApprovedByBankDuplicate = '362 Not Approved by Bank - Duplicate',
  ApplicationRegisteredNotApprovedByBankReRegister = '363 Not Approved by Bank - Re-register',
  ApplicationRegisteredNotApprovedByBankAddCoApplicant = '364 Not Approved by Bank - Add Co-Applicant',
  ApplicationRegisteredNotApprovedByBankOther = '369 Not Approved by Bank - Other',
  ApplicationRegisteredCancelledByAxo = '381 Cancelled by Axo',
  ApplicationRegisteredCancelledByBank = '382 Cancelled by Bank',
  ApplicationRegisteredWithdrawnByAxo = '391 Withdrawn by Axo',
  ApplicationApprovedPendingCustomerDecision = '411 Pending Customer Decision',
  ApplicationApprovedPendingCustomerDecisionOther = '449 Pending Customer Decision - Other',
  ApplicationApprovedAcceptedByCustomer = '451 Accepted by Customer',
  ApplicationApprovedAcceptedByAxo = '452 Accepted by Axo',
  ApplicationApprovedNotAcceptedByCustomer = '461 Not Accepted by Customer',
  ApplicationApprovedNotAcceptedByAxo = '462 Not Accepted by Axo',
  ApplicationApprovedNotAcceptedByCustomerOther = '468 Not Accepted by Customer - Other',
  ApplicationApprovedNotAcceptedByAxoOther = '469 Not Accepted by Axo - Other',
  ApplicationApprovedCancelledByAxo = '481 Cancelled by Axo',
  ApplicationApprovedCancelledByBank = '482 Cancelled by Bank',
  ApplicationApprovedWithdrawnByAxo = '491 Withdrawn by Axo',
  OfferAcceptedPendingBankDisbursementDecision = '511 Pending Bank Disbursement Decision',
  OfferAcceptedPendingBankDisbursementDecisionValidation = '512 Pending Bank Disbursement Decision - Validation',
  OfferAcceptedPendingBankDisbursementDecisionFormSubmit = '513 Pending Bank Disbursement Decision - Form Submit',
  OfferAcceptedPendingBankDisbursementDecisionTaxConsent = '514 Pending Bank Disbursement Decision - eTax Consent',
  OfferAcceptedPendingBankDisbursementDecisionPSD2Consent = '515 Pending Bank Disbursement Decision - PSD2 Consent',
  OfferAcceptedPendingBankDisbursementDecisionDocuments = '516 Pending Bank Disbursement Decision - Documents',
  OfferAcceptedPendingBankDisbursementDecisionInformation = '517 Pending Bank Disbursement Decision - Information',
  OfferAcceptedPendingBankDisbursementDecisionCoApplicant = '518 Pending Bank Disbursement Decision - Co-Applicant',
  OfferAcceptedPendingBankDisbursementDecisionSignature = '519 Pending Bank Disbursement Decision - Signature',
  OfferAcceptedPendingBankDisbursementDecisionBank = '549 Pending Bank Disbursement Decision - Bank',
  OfferAcceptedApprovedForDisbursementByBank = '551 Approved for Disbursement by Bank',
  OfferAcceptedNotApprovedForDisbursementByBank = '561 Not Approved for Disbursement by Bank',
  OfferAcceptedNotApprovedForDisbursementByBankDuplicate = '562 Not Approved for Disbursement by Bank - Duplicate',
  OfferAcceptedNotApprovedForDisbursementByBankReRegister = '563 Not Approved for Disbursement by Bank - Re-register',
  OfferAcceptedNotApprovedForDisbursementByBankAddCoApplicant = '564 Not Approved for Disbursement by Bank - Add Co-Applicant',
  OfferAcceptedNotApprovedForDisbursementByBankOther = '569 Not Approved for Disbursement by Bank - Other',
  OfferAcceptedCancelledByAxo = '581 Cancelled by Axo',
  OfferAcceptedCancelledByBank = '582 Cancelled by Bank',
  OfferAcceptedWithdrawnByAxo = '591 Withdrawn by Axo',
  LoanApprovedForDisbursementPendingDisbursement = '611 Pending Disbursement',
  LoanApprovedForDisbursementPendingDisbursementOther = '649 Pending Disbursement - Other',
  LoanApprovedForDisbursementDisbursedByBank = '651 Disbursed by Bank',
  LoanApprovedForDisbursementNotDisbursedByBank = '661 Not Disbursed by Bank',
  LoanApprovedForDisbursementCancelledByAxo = '681 Cancelled by Axo',
  LoanApprovedForDisbursementCancelledByBank = '682 Cancelled by Bank',
  LoanApprovedForDisbursementWithdrawnByAxo = '691 Withdrawn by Axo',
}

export const declinedStatuses = [
  LoanQuoteStatus.ApplicationRegisteredNotApprovedByBank,
  LoanQuoteStatus.ApplicationRegisteredNotApprovedByBankDuplicate,
  LoanQuoteStatus.ApplicationRegisteredNotApprovedByBankReRegister,
  LoanQuoteStatus.ApplicationRegisteredNotApprovedByBankAddCoApplicant,
  LoanQuoteStatus.ApplicationRegisteredNotApprovedByBankOther,
  LoanQuoteStatus.ApplicationRegisteredCancelledByAxo,
  LoanQuoteStatus.ApplicationRegisteredCancelledByBank,
  LoanQuoteStatus.ApplicationRegisteredWithdrawnByAxo,
  LoanQuoteStatus.ApplicationApprovedNotAcceptedByCustomer,
  LoanQuoteStatus.ApplicationApprovedNotAcceptedByAxo,
  LoanQuoteStatus.ApplicationApprovedNotAcceptedByCustomerOther,
  LoanQuoteStatus.ApplicationApprovedNotAcceptedByAxoOther,
  LoanQuoteStatus.ApplicationApprovedCancelledByAxo,
  LoanQuoteStatus.ApplicationApprovedCancelledByBank,
  LoanQuoteStatus.ApplicationApprovedWithdrawnByAxo,
  LoanQuoteStatus.OfferAcceptedNotApprovedForDisbursementByBank,
  LoanQuoteStatus.OfferAcceptedNotApprovedForDisbursementByBankDuplicate,
  LoanQuoteStatus.OfferAcceptedNotApprovedForDisbursementByBankReRegister,
  LoanQuoteStatus.OfferAcceptedNotApprovedForDisbursementByBankAddCoApplicant,
  LoanQuoteStatus.OfferAcceptedNotApprovedForDisbursementByBankOther,
  LoanQuoteStatus.OfferAcceptedCancelledByAxo,
  LoanQuoteStatus.OfferAcceptedCancelledByBank,
  LoanQuoteStatus.OfferAcceptedWithdrawnByAxo,
  LoanQuoteStatus.LoanApprovedForDisbursementNotDisbursedByBank,
  LoanQuoteStatus.LoanApprovedForDisbursementCancelledByAxo,
  LoanQuoteStatus.LoanApprovedForDisbursementCancelledByBank,
  LoanQuoteStatus.LoanApprovedForDisbursementWithdrawnByAxo,
];
