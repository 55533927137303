import { Ref, useEffect, useRef } from 'react';
import { useTrustpilotScript } from './useTrustpilotScript';

type Props = {
  locale: string;
  templateId: string;
  businessId: string;
  link: string;
  height: string;
  width?: string;
  theme?: 'light';
  dataProps?: { [key: string]: string };
};

export const Trustpilot = ({
  locale,
  templateId,
  businessId,
  link,
  height,
  width = '100%',
  theme = 'light',
  dataProps = {},
}: Props) => {
  useTrustpilotScript();
  const ref = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  const data = Object.entries(dataProps).reduce<{ [key: string]: string }>(
    (acc, [key, value]) => {
      acc[`data-${key}`] = value;
      return acc;
    },
    {},
  );

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={locale}
      data-template-id={templateId}
      data-businessunit-id={businessId}
      data-style-height={height}
      data-style-width={width}
      data-theme={theme}
      {...data}
    >
      <a href={link} target="_blank" rel="noreferrer">
        Trustpilot
      </a>
    </div>
  );
};

declare global {
  interface Window {
    Trustpilot: {
      loadFromElement: (ref: Ref<() => void>, load: boolean) => void;
    };
  }
}
