import { IEnrichedLoanQuote } from '../../models/loan-quote';
import { Button, ContentBox, Icons } from '@a2755/ui-components';
import styles from './LoanQuoteListItem.module.scss';
import { useState } from 'react';
import { classNames } from '../../utils/classNames';
import LenderLogo from '../LenderLogo/LenderLogo';
import { currencyFormatter } from '../../utils/currency';
import { durationFormatter } from '../../utils/duration';
import { rateFormatter } from '../../utils/rate';
import { translation } from '../../utils/translations';

export enum QuoteState {
  Recommended,
  Selected,
  PreviouslySelected,
  Disbursed,
  None,
}

interface ILoanQuoteListItem {
  quote: IEnrichedLoanQuote;
  action?: () => void;
  state: QuoteState;
}

export function LoanQuoteListItem({
  quote,
  action,
  state,
}: ILoanQuoteListItem) {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const { t } = translation();
  const toggleDetails = () => {
    setDetailsOpen((prevState) => !prevState);
  };

  const lender = quote.Lender;

  const isSelected = state === QuoteState.Selected;
  const isPreviouslySelected = state === QuoteState.PreviouslySelected;
  const isDisbursed = state === QuoteState.Disbursed;

  const getText = (state: QuoteState) => {
    switch (state) {
      case QuoteState.Recommended:
        return t('Recommended');
      case QuoteState.Selected:
        return t('Selected');
      case QuoteState.PreviouslySelected:
        return t('Previously selected');
      case QuoteState.Disbursed:
        return t('Disbursed');
      default:
        return '';
    }
  };

  const getIcon = (state: QuoteState) => {
    switch (state) {
      case QuoteState.Recommended:
        return <Icons.ThumbUp size="xs" />;
      case QuoteState.Selected:
        return <Icons.CheckMarkWhite size="xs" />;
      case QuoteState.PreviouslySelected:
        return <Icons.Archive size="xs" />;
      case QuoteState.Disbursed:
        return <Icons.ThumbUp size="xs" />;
      default:
        return '';
    }
  };

  const getColour = (state: QuoteState) => {
    switch (state) {
      case QuoteState.Recommended:
        return '--success-500';
      case QuoteState.Selected:
        return '--info-700';
      case QuoteState.PreviouslySelected:
        return '--light-700';
      case QuoteState.Disbursed:
        return '--success-500';
      default:
        return '--success-500';
    }
  };

  const hasBorder = (state: QuoteState) => {
    switch (state) {
      case QuoteState.Recommended:
        return true;
      case QuoteState.Selected:
        return true;
      case QuoteState.PreviouslySelected:
        return false;
      case QuoteState.Disbursed:
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      <ContentBox
        classes={{
          root: styles.contentBox,
          tag: styles.recommendedTag,
        }}
        tagText={getText(state)}
        tagIcon={getIcon(state)}
        active={hasBorder(state)}
        activeColour={getColour(state)}
      >
        <div className={styles.detailsContainer}>
          <div className={classNames(styles.lenderLogoContainer)}>
            {lender && (
              <LenderLogo lender={lender} className={styles.lenderLogo} />
            )}
          </div>
          <div className={styles.detailsGrid}>
            <div className={styles.detailItem}>
              <span className={styles.label}>{t('Loan amount')}</span>
              <span className={styles.value}>
                {currencyFormatter(quote.Amount)}
              </span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.label}>{t('Loan period')}</span>
              <span className={styles.value}>
                {durationFormatter(quote.Duration)}
              </span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.label}>{t('Nominal interest rate')}</span>
              <span className={styles.value}>
                {rateFormatter(quote.NominalRate || 0)}
              </span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.label}>{t('Monthly pay')}</span>
              <span className={styles.value}>
                {currencyFormatter(quote.MonthlyPayment)}
              </span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.label}>{t('Loan cost')}</span>
              <span className={styles.value}>
                {currencyFormatter(quote.StartupFee)}
              </span>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              variant="secondary"
              onClick={toggleDetails}
              endIcon={
                <Icons.Caret
                  size="sm"
                  color={{ primary: '--dark-700' }}
                  classes={{ root: detailsOpen ? styles.caretFlipped : '' }}
                />
              }
              fontVariant={'secondary'}
              classes={{ root: styles.button }}
            >
              {t('Details')}
            </Button>
            <Button
              variant="primary"
              onClick={action}
              classes={{
                root: classNames(
                  styles.button,
                  styles.actionButton,
                  isPreviouslySelected && styles.black,
                ),
              }}
              disabled={isDisbursed}
            >
              {isSelected || isPreviouslySelected ? t('Continue') : t('Choose')}
            </Button>
          </div>
        </div>
      </ContentBox>
      {detailsOpen && (
        <div className={styles.detailBanner}>
          <span className={styles.title}>{t('Useful information')}</span>
          <div className={styles.info}>
            {lender.Info[0] && (
              <>
                <Icons.CircleInfo
                  size="xs"
                  color={{ primary: '--dark-500' }}
                  classes={{ root: styles.icon }}
                />
                <span>{lender.Info[0].InfoText}</span>
              </>
            )}
          </div>
          <div className={styles.info}>
            {lender.Info[1] && (
              <>
                <Icons.LightBulb
                  size="xs"
                  color={{ primary: '--dark-300' }}
                  classes={{ root: styles.icon }}
                />
                <span>{lender.Info[1].InfoText}</span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
