/**
 * Takes a list of css classes and combines them into a single string containing
 * all the classes separated by spaces. The function ignores any undefined
 * values, which means that the list can contain optional/conditional values.
 *
 * An example of how it could be used:
 *
 * <input
 *   className={classNames(
 *     styles.myInput,
 *     hasFocus ? styles.focused : styles.unfocused,
 *     validState && styles[validState], // validState is "valid" | "invalid" | undefined
 *     disabled && styles.disabled,
 *   )}
 * ></input>
 *
 */
export const classNames = (
  ...classNameList: (string | undefined | false)[]
): string => {
  return classNameList.reduce(
    (sum, next) => sum + (next ? ` ${next}` : ''),
    '',
  ) as string;
};
