import { ILoanQuote } from '../../../models/loan-quote';

export async function acceptQuote(
  token: string,
  quoteID: string,
  force: boolean,
  marketCountry: string,
) {
  const url = new URL(`/loan-quote/${quoteID}/accept`, BASE_URL);
  const response = await fetch(url, {
    method: 'PATCH',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({
      AcceptedBy: 'MyPage',
      AcceptedByCustomer: true,
      ForceAccept: force,
      MarketCountry: marketCountry,
    }),
  });
  if (!response.ok) {
    throw new Error(`Network response was not OK: ${response.status}`);
  }
  return (await response.json()) as ILoanQuote;
}
const BASE_URL = process.env.REACT_APP_API_URL;
