import { createPortal } from 'react-dom';

const headRoot = document.head;

interface HeadProps {
  children: React.ReactNode;
}

export const Head = ({ children }: HeadProps) => {
  return createPortal(children, headRoot);
};
