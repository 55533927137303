import { Icons } from '@a2755/ui-components';
import { translation } from '../../utils/translations';
import styles from './ApprovalIndicator.module.scss';

interface ApprovalIndicatorProps {
  numberOfOffers: number;
}

export const ApprovalIndicator = ({
  numberOfOffers,
}: ApprovalIndicatorProps) => {
  const { t } = translation();
  return (
    <div className={styles.approvalIndicator}>
      <Icons.CircleCheckMarkValid size="md" />
      {t(
        'All our bank partners have processed your application. You received',
      )}{' '}
      {numberOfOffers} {numberOfOffers === 1 ? t('offer') : t('offers')}.
    </div>
  );
};
