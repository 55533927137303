import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useCreateTicket } from '../../hooks/mutations/useCreateTicket';
import { applicationKeys } from '../../hooks/queries/cache-keys/applicationKeys';
import { IEvent } from '../../models/ws-connection';
import { ApplicationContext } from '../../store/context';

export function useWebSocket() {
  const { mutateAsync: createTicket } = useCreateTicket();
  const [latestMessage, setLatestMessage] = useState<MessageEvent | null>(null);

  const [retryCount, setRetryCount] = useState(0);
  const [status, setStatus] = useState(Status.None);
  const retry = useCallback(() => {
    setRetryCount((prev) => prev + 1);
  }, []);
  const manualRetry = useMemo(() => {
    const isFailed = status === Status.Error || status === Status.Disconnected;
    if (retryCount >= MAX_RETRIES && isFailed) {
      return () => setRetryCount(0);
    }
    return null;
  }, [retryCount, status]);
  const refreshTicket = useMemo(() => {
    if (retryCount >= MAX_RETRIES) return null;
    return () => createTicket();
  }, [createTicket, retryCount]);

  useEffect(() => {
    let next: WebSocket | null = null,
      canceled = false;

    function onMessage(e: MessageEvent) {
      setLatestMessage(e);
    }

    function onClose() {
      setStatus(Status.Disconnected);
      retry();
    }

    function onError() {
      setStatus(Status.Error);
      retry();
    }

    async function connect() {
      if (!refreshTicket) return;
      const ticket = await refreshTicket();
      if (canceled) return;
      const wsurl = process.env.REACT_APP_WS_URL;
      if (!wsurl) throw new Error(`Missing env var REACT_APP_WS_URL`);
      const url = new URL(wsurl);
      url.searchParams.set('ticket', ticket.ID);
      next = new WebSocket(url);
      setStatus(Status.Connecting);
      next.addEventListener('message', onMessage);
      next.addEventListener('close', onClose);
      next.addEventListener('error', onError);
    }

    connect();

    return () => {
      canceled = true;
      if (next) {
        if (!next.CLOSED) {
          next.removeEventListener('message', onMessage);
          next.removeEventListener('close', onClose);
          next.removeEventListener('error', onError);
          next.close();
          setStatus(Status.Closing);
        }
      }
    };
  }, [refreshTicket, retry]);

  return useMemo(
    () => ({ latestMessage, manualRetry, status }),
    [latestMessage, manualRetry, status],
  );
}
enum Status {
  None,
  Connecting,
  Connected,
  Closing,
  Disconnected,
  Error,
}
const MAX_RETRIES = 3;

export function WebSocketProvider({ children }: { children: ReactNode }) {
  const { latestMessage, manualRetry } = useWebSocket();
  const client = useQueryClient();
  const {
    state: {
      user: { token },
      applicationID,
    },
  } = useContext(ApplicationContext);
  const loanQuotesKey = useMemo(
    () =>
      applicationKeys.quotes({
        applicationID,
        token,
      }),
    [applicationID, token],
  );

  useEffect(() => {
    if (latestMessage) {
      const event = JSON.parse(latestMessage.data) as IEvent<unknown>;

      switch (event.source) {
        case 'loan-quote':
          client.invalidateQueries(loanQuotesKey);
          break;
      }
    }
  }, [latestMessage, client, loanQuotesKey]);

  useEffect(() => {
    if (manualRetry) {
      // AppToaster.show({
      //   intent: 'danger',
      //   message: 'WebSocket disconnected. Retry?',
      //   action: {
      //     text: 'Retry connection',
      //     onClick: manualRetry,
      //   },
      // });
    }
  }, [manualRetry]);

  return <>{children}</>;
}
