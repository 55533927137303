import { H1 } from '@a2755/ui-components';
import { translation } from '../../utils/translations';

export function HelpPage() {
  const { t } = translation();
  return (
    <>
      <H1>{t('Something went wrong')}</H1>
      <p>{t('It appears that we are experiencing technical difficulties')}</p>
      <p>
        {t(
          'To fix the error, you can try refreshing the page. Please contact our customer service at',
        )}{' '}
        <a href={`tel:${t('01234 56789')}`}>{t('01234 56789')}</a>
      </p>
    </>
  );
}
