import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import { IVariant } from '@a2755/ui-components';
import { getRandomString } from '../../utils/getRandomString';

interface ToastElements {
  header?: ReactNode;
  content?: ReactNode;
  variety?: IVariant;
}
interface Toast {
  id: string;
  elements: ToastElements;
}

type State = Readonly<Toast[]>;
const initialState: State = [];

type Action =
  | {
      type: 'toast/display';
      payload: ToastElements;
    }
  | { type: 'toast/dismiss'; payload: string };

const ToastContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => console.log('empty dispatch'),
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'toast/display':
      return [
        ...state,
        {
          id: getRandomString(),
          elements: action.payload,
        },
      ];

    case 'toast/dismiss':
      return state.filter((toast) => toast.id !== action.payload);

    default:
      return state;
  }
};

type Props = {
  children: ReactNode;
};

export const Toaster = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ToastContext.Provider value={{ state, dispatch }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToastState = () => {
  const { state } = useContext(ToastContext);
  return state;
};

export const useToastActions = () => {
  const { dispatch } = useContext(ToastContext);

  const displayToast = useCallback(
    (elements: ToastElements) => {
      dispatch({
        type: 'toast/display',
        payload: elements,
      });
    },
    [dispatch],
  );

  const dismissToast = useCallback(
    (id: string) => {
      dispatch({ type: 'toast/dismiss', payload: id });
    },
    [dispatch],
  );

  return { displayToast, dismissToast };
};
