import { Icons } from '@a2755/ui-components';
import { translation } from '../../utils/translations';
import { Trustpilot } from '../Trustpilot';
import styles from './Footer.module.scss';

export const Footer = () => {
  const { t } = translation();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.trustpilot}>
          <Trustpilot
            businessId="5d949991dff97e00014e194b"
            templateId="53aa8807dec7e10d38f59f32"
            height="150px"
            locale="fi-FI"
            link="https://fi.trustpilot.com/review/axolaina.fi"
          />
        </div>
        <div className={styles.contactInfo}>
          <a href={`tel:${t('01234 56789')}`} className={styles.detail}>
            <Icons.Telephone size="sm" />
            {t('01234 56789')}
          </a>
          <a
            href={`mailto:${t('customerservice@axofinance.com')}`}
            className={styles.detail}
          >
            <Icons.Email size="sm" />
            {t('customerservice@axofinance.com')}
          </a>
        </div>
        <div className={styles.orgInfo}>
          {`${new Date().getFullYear().toString()} © Axo Finance Oy`} <br />
          Y-tunnus: 2984343-1
        </div>
      </div>
    </div>
  );
};
