import { createContext } from 'react';
import { Action } from './actions';
import { initialState, State } from './reducer';

export const ApplicationContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => undefined,
});
