import { ITicket } from '../../../models/ws-connection';

const BASE_URL = process.env.REACT_APP_API_URL;

export async function createTicket(token: string): Promise<ITicket> {
  const url = new URL(`/ws-connection/ticket`, BASE_URL);
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: token,
    },
  });
  if (!response.ok) {
    throw new Error(`Network response was not OK: ${response.status}`);
  }
  return await response.json();
}
