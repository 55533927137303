import { memo, useState } from 'react';
import { ILender } from '../../models/lender';
import * as logos from './logo';

const loadLenderLogo = (lenderId: string): string | undefined => {
  const ls = logos as { [k: string]: string };

  if (ls[lenderId] === undefined) {
    return undefined;
  }

  return ls[lenderId] as string;
};

type Props = {
  lender: ILender;
  className?: string;
  grayscale?: boolean;
};

const LenderLogo = ({ lender, className, grayscale = false }: Props) => {
  const src = loadLenderLogo(lender.ShortName);
  const [loading, setLoading] = useState(true);
  return (
    <>
      {!src || src === '' ? null : (
        <img
          src={src}
          alt={loading ? '' : `Logo ${lender.Name}`}
          className={className}
          onLoad={() => setLoading(false)}
          onError={() => setLoading(false)}
          style={{ ...(grayscale ? { filter: 'grayscale(100%)' } : {}) }}
        />
      )}
    </>
  );
};

export default memo(LenderLogo);
