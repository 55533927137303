import { Button, H4, Icons, Modal } from '@a2755/ui-components';
import { useAcceptQuote } from '../../hooks/mutations/useAcceptQuote';
import { translation } from '../../utils/translations';
import { IEnrichedLoanQuote } from '../../models/loan-quote';
import LenderLogo from '../LenderLogo/LenderLogo';
import styles from './ChangeAcceptOfferModal.module.scss';

interface IAcceptOfferModal {
  oldOffer: IEnrichedLoanQuote;
  newOffer: IEnrichedLoanQuote;
  marketCountry: string;
  closeModal: () => void;
}

export function ChangeAcceptOfferModal({
  oldOffer,
  newOffer,
  marketCountry,
  closeModal,
}: IAcceptOfferModal) {
  const { t } = translation();
  const accept = useAcceptQuote(newOffer.ID, marketCountry, true);
  const oldLender = oldOffer.Lender;
  const newLender = newOffer.Lender;

  const handleAccept = () => {
    accept.mutateAsync();
    closeModal();
  };

  return (
    <Modal
      topBorderColor="--primary-500"
      onBackgroundClick={closeModal}
      onCloseButton={closeModal}
      classes={{ root: styles.modalRoot, box: styles.modal }}
    >
      <div className={styles.modalContent}>
        <H4>{t('Change your selection')}</H4>
        <span className={styles.subtitle}>
          {t('You are attempting to change your selected offer')}
        </span>
        <div className={styles.lenders}>
          <div className={styles.lenderLogoContainer}>
            <div className={styles.lenderLogo}>
              {oldLender && <LenderLogo lender={oldLender} />}
            </div>
            {t('Currently selected')}
          </div>
          <Icons.ArrowRight
            size="sm"
            color={{ primary: '--success-500' }}
            classes={{ root: styles.arrow }}
          />
          <div className={styles.lenderLogoContainer}>
            <div className={styles.lenderLogo}>
              {newLender && <LenderLogo lender={newLender} />}
            </div>
            {t('New selection')}
          </div>
        </div>
        <div className={styles.actionContainer}>
          {t('Are you sure you want to change your selection?')}
          <div className={styles.buttons}>
            <Button variant="secondary" onClick={closeModal}>
              {t('Go back')}
            </Button>
            <Button variant="primary" onClick={handleAccept}>
              {t('Confirm and proceed')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
