import { Header } from '@a2755/ui-components';
import { useCallback, useEffect, useReducer } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Outlet } from 'react-router-dom';
import { AuthProvider } from '../../components/AuthProvider';
import { useToastActions } from '../../components/Toaster';
import ToastList from '../../components/Toaster/ToastList';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { Footer } from '../../components/Footer';
import { WebSocketProvider } from '../../components/WebSocket';
import {
  publish,
  QueryErrorEvents,
  subscribe,
  unsubscribe,
} from '../../events';
import { ApplicationContext } from '../../store/context';
import { initialState, reducer } from '../../store/reducer';
import styles from './Main.module.scss';
import { translation } from '../../utils/translations';
import { Head } from '../../components/Head';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => publish(QueryErrorEvents.failedToFetch, error),
  }),
});

export function Main() {
  const [state, originalDispatch] = useReducer(reducer, initialState);
  const { t } = translation();
  const { displayToast } = useToastActions();

  const dispatch: typeof originalDispatch = useCallback(
    (action) => {
      if (process.env.NODE_ENV !== 'production') {
        console.debug('Action dispatched:', action);
      }

      originalDispatch(action);
    },
    [originalDispatch],
  );

  useEffect(() => {
    function handleError(e: CustomEventInit) {
      console.log(e);
      displayToast({
        header: t('An error occured'),
        content: (
          <p>
            {t(
              'There was an issue fetching your offers. Please try again or contact support at',
            )}{' '}
            <a href={`tel:${t('01234 56789')}`}>{t('01234 56789')}</a>
          </p>
        ),
        variety: 'error',
      });
    }
    subscribe(QueryErrorEvents.failedToFetch, handleError);
    return () => unsubscribe(QueryErrorEvents.failedToFetch, handleError);
  });

  return (
    <ApplicationContext.Provider value={{ state, dispatch }}>
      <QueryClientProvider client={queryClient}>
        <Head>
          <title>{t('My Page')}</title>
          <meta name="description" content={t('Axo Finance: My Page')} />
        </Head>
        <AuthProvider>
          <WebSocketProvider>
            <Header
              showOpeningBar={false}
              compact_opening_hours_text=""
              country="fi"
              full_opening_hours_text=""
              header_links={[]}
              helper_text=""
              telephone=""
              timezone="Europe/Helsinki"
              working_hours={[]}
              focusMode={false}
            />
            <ToastList />
            <div className={styles.container}>
              <ErrorBoundary
                onError={() =>
                  displayToast({
                    header: t('An error occured'),
                    content: (
                      <p>
                        {t(
                          'A technical error has occured. Please try again or contact support at',
                        )}{' '}
                        <a href={`tel:${t('01234 56789')}`}>
                          {t('01234 56789')}
                        </a>
                      </p>
                    ),
                    variety: 'error',
                  })
                }
              >
                <Outlet />
              </ErrorBoundary>
            </div>
            <Footer />
          </WebSocketProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </AuthProvider>
      </QueryClientProvider>
    </ApplicationContext.Provider>
  );
}
