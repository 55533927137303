import { Toast } from '@a2755/ui-components';
import { useToastActions, useToastState } from './Toaster';

const ToastList = () => {
  const toasts = useToastState();
  const { dismissToast } = useToastActions();

  if (toasts.length < 1) {
    return null;
  }

  return (
    <div className="sticky flex flex-col gap-1">
      {toasts.map((t) => (
        <Toast
          key={t.id}
          dismiss={() => dismissToast(t.id)}
          header={t.elements.header}
          variant={t.elements.variety}
        >
          {t.elements.content}
        </Toast>
      ))}
    </div>
  );
};

export default ToastList;
