import { Button } from '@a2755/ui-components';
import { translation } from '../../utils/translations';
import styles from './CreditScore.module.scss';

interface ICreditScore {
  text: string;
}

export const CreditScore = ({ text }: ICreditScore) => {
  const { t } = translation();

  return (
    <div className={styles.actionBar}>
      <span className={styles.text}>{text}</span>
      <div className={styles.buttons}>
        <Button
          variant="primary"
          onClick={() =>
            window.open('https://uscore.fi/?utm_source=Axo&utm_medium=MyPage')
          }
        >
          {t('Check credit score')}
        </Button>
      </div>
    </div>
  );
};
