import { Button, H4, Modal } from '@a2755/ui-components';
import { useAcceptQuote } from '../../hooks/mutations/useAcceptQuote';
import { translation } from '../../utils/translations';
import { IEnrichedLoanQuote } from '../../models/loan-quote';
import LenderLogo from '../LenderLogo/LenderLogo';
import styles from './AcceptOfferModal.module.scss';

interface IAcceptOfferModal {
  offer: IEnrichedLoanQuote;
  marketCountry: string;
  closeModal: () => void;
}

export function AcceptOfferModal({
  offer,
  marketCountry,
  closeModal,
}: IAcceptOfferModal) {
  const { t } = translation();
  const accept = useAcceptQuote(offer.ID, marketCountry, false);
  const lender = offer.Lender;

  const handleAccept = () => {
    accept.mutateAsync();
    closeModal();
  };

  return (
    <Modal
      topBorderColor="--primary-500"
      onBackgroundClick={closeModal}
      onCloseButton={closeModal}
      classes={{ box: styles.modal }}
    >
      <div className={styles.modalContent}>
        <H4>{t('Confirm your selection')}</H4>
        <span className={styles.subtitle}>
          {t('You have chosen the offer from')}
        </span>
        <div className={styles.lenderLogoContainer}>
          {lender && <LenderLogo lender={lender} />}
        </div>
        <div className={styles.actionContainer}>
          {t('Do you want to continue with')}{' '}
          <span className={styles.bold}>{lender?.Name}</span>?
          <div className={styles.buttons}>
            <Button variant="secondary" onClick={closeModal}>
              {t('Go back')}
            </Button>
            <Button variant="primary" onClick={handleAccept}>
              {t('Confirm and proceed to bank page')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
