import jwtDecode from 'jwt-decode';
import { IToken, IUser } from '../models/user';
import { Action } from './actions';

export type State = {
  error: string | undefined;
  user: IUser;
  applicationID: string;
};

export const initialState: State = {
  error: undefined,
  user: {
    email: '',
    token: '',
    roles: [],
    clientID: '',
  },
  applicationID: '',
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'Set user': {
      console.log('Set user', action.payload);
      const token = jwtDecode<IToken>(action.payload.JWT);
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload.JWT,
          email: token.Email,
          clientID: token.ClientID,
          roles: token.Roles,
        },
        applicationID: action.payload.applicationID,
      };
    }
    case 'Set error':
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
