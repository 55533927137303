const languageEnv = process.env.REACT_APP_LANGUAGE as Languages;

export const translation = () => {
  const language =
    languageEnv && Object.values(Languages).includes(languageEnv)
      ? languageEnv
      : Languages.en;
  const translate = (key: keyof typeof translations) => {
    return translations[key][language];
  };
  return { t: translate };
};

export enum Languages {
  en = 'en',
  fi = 'fi',
}

const translationsObj = {
  //general
  'Go back': {
    en: 'Go back',
    fi: 'Takaisin',
  },
  'Something went wrong': {
    en: 'Something went wrong',
    fi: 'Jotain meni pieleen',
  },
  'My Page': {
    en: 'My Page',
    fi: 'Omat sivut',
  },
  'Axo Finance: My Page': {
    en: 'Axo Finance: My Page',
    fi: 'Axo Finance: Omat sivut',
  },
  '01234 56789': {
    en: '01234 56789',
    fi: '09 4245 2326',
  },
  'customerservice@axofinance.com': {
    en: 'customerservice@axofinance.com',
    fi: 'asiakaspalvelu@axolaina.fi',
  },

  //homepage
  'Welcome!': {
    en: 'Welcome!',
    fi: 'Tervetuloa!',
  },
  'Your offers': {
    en: 'Your offers',
    fi: 'Tarjouksesi',
  },
  'Loading your offers': {
    en: 'Loading your offers...',
    fi: 'Ladataan tarjouksia...',
  },
  'As soon as you recieve more offers': {
    en: 'As soon as you receive more offers we will notify you via email and sms and you will be able to preview them below.',
    fi: 'Saatuamme kaikilta yhteistyökumppaneiltamme vastauksen hakemukseesi, saat meiltä heti viestin ja pääset vertailemaan saamiasi tarjouksia.',
  },
  'Your application ID is': {
    en: 'Your application ID is',
    fi: 'Hakemusnumerosi on',
  },
  'Change my selection': {
    en: 'Change my selection',
    fi: 'Katso kaikki tarjoukset',
  },

  //approval indicator
  'All our bank partners have processed your application. You received': {
    en: 'All our bank partners have processed your application. You received',
    fi: 'Kaikki lainanantajat ovat käsitelleet hakemuksesi. Sait yhteensä',
  },
  offers: {
    en: 'offers',
    fi: 'tarjousta',
  },
  offer: {
    en: 'offer',
    fi: 'tarjouksen',
  },

  //statuses
  'Loading your offers, please wait': {
    en: 'Loading your offers, please wait',
    fi: 'Tarjouksia ladataan, odotathan rauhassa',
  },
  'You have made your selection and are awaiting for the loan to be paid out.':
    {
      en: 'You have made your selection and are awaiting for the loan to be paid out.',
      fi: 'Lainatarjous on hyväksytty ja odottaa allekirjoitustasi.',
    },
  'You received your first offer!': {
    en: 'You received your first offer! Please wait for all lenders to respond, to make sure you get the best offer.',
    fi: 'Olet saanut ensimmäisen lainatarjouksen! Odotamme vielä lisää tarjouksia.',
  },
  'Please wait for all lenders to respond': {
    en: 'Please wait for all lenders to respond, to make sure you get the best offer.',
    fi: 'Odotamme lainatarjouksia kumppaneiltamme.',
  },
  'All of our bank partners have responded. Please make your selection below.':
    {
      en: 'All of our bank partners have responded. Please make your selection below.',
      fi: 'Kaikki lainanantajat ovat vastanneet. Tutustu lainatarjouksiin alta.',
    },
  'All banks have responded.': {
    en: 'All banks have responded.',
    fi: 'Kaikki lainanantajat ovat vastanneet.',
  },
  'Unfortunately, your offer has been cancelled.': {
    en: 'Unfortunately, your offer has been cancelled.',
    fi: 'Valitettavasti lainatarjouksesi on peruttu.',
  },
  'Your loan has been disbursed and will appear in your account in a few days.':
    {
      en: 'Your loan has been disbursed and will appear in your account in a few days.',
      fi: 'Lainasi on lähtenyt maksuun ja näkyy tililläsi muutaman päivän sisällä',
    },

  //no offers
  'Thank you for you application!': {
    en: 'Thank you for you application!',
    fi: 'Kiitos lainahakemuksestasi!',
  },
  'Our lenders have processed you application, but unfortunately, did not return an offer.':
    {
      en: 'Our lenders have processed you application, but unfortunately, did not return an offer.',
      fi: 'Kaikki yhteistyökumppanimme ovat käsitelleet hakemuksesi, mutta valitettavasti et saanut yhtäkään tarjousta.',
    },
  'All our partners have now processed your loan application and unfortunately the offers have been declined.':
    {
      en: 'All our partners have now processed your loan application and unfortunately the offers have been declined.',
      fi: 'Kaikki yhteistyökumppanimme ovat nyt käsitelleet lainahakemuksesi ja valitettavasti lainapäätös on kielteinen.',
    },
  'Please consult the guides below on how to improve your credit score': {
    en: 'Please consult the guides bellow on how to improve your credit score and maximizing your chances of getting a loan',
    fi: 'Katso, kuinka pankit määrittävät lainapotentiaalisi ja ota taloutesi haltuun. Tarkista luottopisteesi ilmaiseksi!',
  },
  "Discover your credit score for free and in less than 2 minutes - don't wait any longer to take control of your finances.":
    {
      en: "Discover your credit score for free and in less than 2 minutes - don't wait any longer to take control of your finances.",
      fi: 'Selvitä luottopisteesi ilmaiseksi vain kahdessa minuutissa - ja ota taloutesi haltuun jo tänään.',
    },
  'Loan application guide': {
    en: 'Loan application guide',
    fi: 'Syitä lainahakemuksen hylkäykseen',
  },
  'Check credit score': {
    en: 'Check credit score',
    fi: 'Tarkista luottopisteesi',
  },

  //offer
  Recommended: {
    en: 'Recommended',
    fi: 'Suositeltu',
  },
  Selected: {
    en: 'Selected',
    fi: 'Valittu',
  },
  Disbursed: {
    en: 'Disbursed',
    fi: 'Maksussa',
  },
  'Previously selected': {
    en: 'Previously selected',
    fi: 'Edellinen valintasi',
  },
  'Loan amount': {
    en: 'Loan amount',
    fi: 'Lainasumma',
  },
  'Loan period': {
    en: 'Loan period',
    fi: 'Takaisinmaksuaika',
  },
  'Nominal interest rate': {
    en: 'Nominal interest rate',
    fi: 'Nimelliskorko',
  },
  'Monthly pay': {
    en: 'Monthly pay',
    fi: 'Kuukausierä',
  },
  'Loan cost': {
    en: 'Loan cost',
    fi: 'Avausmaksu',
  },
  'Useful information': {
    en: 'Useful information',
    fi: 'Lisätietoja',
  },
  Details: {
    en: 'Details',
    fi: 'Lue lisää',
  },
  Choose: {
    en: 'Choose',
    fi: 'Valitse',
  },
  Continue: {
    en: 'Continue',
    fi: 'Jatka',
  },
  years: {
    en: 'years',
    fi: 'vuotta',
  },
  year: {
    en: 'year',
    fi: 'vuosi',
  },

  //accept offer modal
  'Confirm your selection': {
    en: 'Confirm your selection',
    fi: 'Vahvista valintasi',
  },
  'You have chosen the offer from': {
    en: 'You have chosen the offer from',
    fi: 'Olet valinnut tarjouksen yhteistyökumppaniltamme',
  },
  'Do you want to continue with': {
    en: 'Do you want to continue with',
    fi: 'Haluatko hyväksyä tarjouksen pankilta',
  },

  'Confirm and proceed to bank page': {
    en: 'Confirm and proceed to bank page',
    fi: 'Hyväksy ja jatka pankin sivuille',
  },

  //change accept offer modal
  'Change your selection': {
    en: 'Change your selection',
    fi: 'Muuta valintaasi',
  },
  'You are attempting to change your selected offer': {
    en: 'You are attempting to change your selected offer',
    fi: 'Olet vaihtamassa lainatarjoustasi',
  },
  'Currently selected': {
    en: 'Currently selected',
    fi: 'Aiemmin valittu',
  },
  'New selection': {
    en: 'New selection',
    fi: 'Uusi valinta',
  },
  'Are you sure you want to change your selection?': {
    en: 'Are you sure you want to change your selection?',
    fi: 'Haluatko varmasti vaihtaa lainatarjouksesi?',
  },
  'Confirm and proceed': {
    en: 'Confirm and continue',
    fi: 'Vahvista ja jatka',
  },

  //error
  'An error occured': {
    en: 'An error occured',
    fi: 'Tapahtui virhe',
  },
  'There was an issue fetching your offers. Please try again or contact support at':
    {
      en: 'There was an issue fetching your offers. Please try again or contact support at',
      fi: 'Tarjousten lataamisessa tapahtui virhe. Päivitä sivu ja yritä uudelleen tai ota yhteyttä asiakaspalveluun numeroon',
    },
  'A technical error has occured. Please try again or contact support at': {
    en: 'A technical error has occured. Please try again or contact support at',
    fi: 'Tapahtui tekninen virhe. Päivitä sivu ja yritä uudelleen tai ota yhteyttä asiakaspalveluumme',
  },
  'It appears that we are experiencing technical difficulties': {
    en: 'It appears that we are experiencing technical difficulties at the moment. We will resolve the issue as soon as possible. We apologize for the inconvenience caused.',
    fi: 'Näyttää siltä, että meillä on tällä hetkellä teknisiä vaikeuksia. Pyrimme ratkaisemaan ongelman mahdollisimman pian. Pahoittelemme tästä aiheutuvaa haittaa.',
  },
  'To fix the error, you can try refreshing the page. Please contact our customer service at':
    {
      en: 'To fix the error, you can try refreshing the page or try again later. If the problem persists, please contact our customer service at',
      fi: 'Virheen korjaamiseksi voit kokeilla sivun päivittämistä tai yrittää myöhemmin uudelleen. Ongelman jatkuessa otathan yhteyttä asiakaspalveluumme numeroon',
    },

  //faq
  'Frequently asked questions': {
    en: 'Frequently asked questions',
    fi: 'Usein kysyttyjä kysymyksiä lainanhausta',
  },
  'FAQ Question 1': {
    en: 'FAQ Question 1',
    fi: 'Miksi sain kielteisen lainapäätöksen?',
  },
  'FAQ Answer 1': {
    en: 'FAQ Answer 1',
    fi: 'Pankki voi antaa hylkäävän lainapäätöksen, mikäli hakija ei täytä lainan myöntämiselle asetettuja vähimmäisvaatimuksia. Mahdollisia syitä kielteiselle lainapäätökselle saattaa olla mm. maksuhäiriömerkintä, lainahakemukselle ei ole merkitty kaikkia olemassa olevia lainoja tai lainahakemuksella on ilmoitettu väärät tulotiedot. Pankit käsittelevät hakemuksen aina asiakaskohtaisesti ja tarvittaessa voit olla yhteydessä kielteisen luottopäätöksen antaneeseen pankkiin.',
  },
  'FAQ Question 2': {
    en: 'FAQ Question 2',
    fi: 'Sain kielteisen lainapäätöksen, milloin voin hakea lainaa uudelleen?',
  },
  'FAQ Answer 2': {
    en: 'FAQ Answer 2',
    fi: 'Voit hakea lainaa uudelleen, kun 30 päivää on kulunut edellisen lainahakemuksen lähettämisestä.',
  },
  'FAQ Question 3': {
    en: 'FAQ Question 3',
    fi: 'Miten voin parantaa mahdollisuuksiani saada laina?',
  },
  'FAQ Answer 3': {
    en: 'FAQ Answer 3',
    fi: 'Voit lisätä todennäköisyyksiäsi lainansaantiin esimerkiksi varmistamalla, että haet vain sen verran lainaa, mitä todella tarvitset. Jos sinulta löytyy useampia vakuudettomia lainoja, kannattaa lainat yhdistää yhdeksi isommaksi lainaksi. Voit myös pyrkiä parantamaan taloudellista tilannettasi, esimerkiksi tekemällä kuukausibudjetin ja karsimalla menoja tai kilpailuttamalla nykyisiä lainojasi edullisemmaksi.',
  },
};

type Translations = keyof typeof translationsObj;

export const translations: Record<
  Translations,
  Record<Languages, string>
> = translationsObj;
