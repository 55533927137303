import { Component, ErrorInfo, ReactNode } from 'react';
import { HelpPage } from '../HelpPage';

interface Props {
  children?: ReactNode;
  onError: (error: Error) => void;
}

interface State {
  hasError: boolean;
}

let singletonInstance: HasErrorSingleton;

class HasErrorSingleton {
  constructor() {
    if (singletonInstance) {
      return singletonInstance;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    singletonInstance = this;
  }

  hasError = false;

  get errorThrown() {
    return this.hasError;
  }

  set errorThrown(a) {
    this.hasError = a;
  }
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log('Uncaught error:', error, errorInfo);
    const e = new HasErrorSingleton();
    if (!e.errorThrown) {
      this.props.onError(error);
      e.errorThrown = true;
    }
  }

  public render() {
    if (this.state.hasError) {
      return <HelpPage />;
    }

    return this.props.children;
  }
}
