import { translation } from './translations';

export const durationFormatter = (duration?: number) => {
  const { t } = translation();
  if (duration === 1) {
    return `${duration} ${t('year')}`;
  } else {
    return `${duration || 0} ${t('years')}`;
  }
};
