import { LoadingGears as Gears } from '@a2755/ui-components';
import styles from './LoadingGears.module.scss';

interface LoadingGearsWithTextProps {
  text: string;
}

export const LoadingGears = ({ text }: LoadingGearsWithTextProps) => {
  return (
    <div className={styles.loadingContainer}>
      <Gears />
      <span className={styles.loadingText}>{text}</span>
    </div>
  );
};
