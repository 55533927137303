import { useContext } from 'react';
import { ApplicationContext } from '../../store/context';
import { useMutation, useQueryClient } from 'react-query';
import { applicationKeys } from '../queries/cache-keys/applicationKeys';
import { acceptQuote } from './api/acceptQuote';

export function useAcceptQuote(
  quoteID: string,
  marketCountry: string,
  forceChange?: boolean,
) {
  const {
    state: {
      user: { token },
      applicationID,
    },
  } = useContext(ApplicationContext);
  const client = useQueryClient();
  return useMutation(
    () => acceptQuote(token, quoteID, !!forceChange, marketCountry),
    {
      onSuccess: (offer) => {
        if (offer.SigningLink) {
          window.location.href = offer.SigningLink;
        }
        client.invalidateQueries(
          applicationKeys.quotes({
            token,
            applicationID,
          }),
        );
      },
    },
  );
}
