import { Route, Routes } from 'react-router-dom';
import './assets/base.css';
import '@a2755/ui-components/dist/index.css';
import './App.module.scss';
import { Main } from './pages/Main/Main';
import { LoanQuoteList } from './pages/LoanQuoteList/LoanQuoteList';
import styles from './App.module.scss';

function App() {
  return (
    <div className={styles.app}>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<LoanQuoteList />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
