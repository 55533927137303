import { LoadingSpinner } from '@a2755/ui-components';
import { ReactNode, useContext, useEffect } from 'react';
import { useMagicToken } from '../../hooks/mutations/useMagicToken';
import { ApplicationContext } from '../../store/context';
import styles from './AuthProvider.module.scss';

interface ILogin {
  children: ReactNode;
}

export function AuthProvider({ children }: ILogin) {
  const { state } = useContext(ApplicationContext);
  const getToken = useMagicToken();

  useEffect(() => {
    if (!state.user.token && !getToken.isLoading && !getToken.isError) {
      getToken.mutateAsync();
    }
  });
  if (state.user.token && !getToken.isLoading) {
    return <>{children}</>;
  } else {
    return (
      <div className={styles.spinnerContainer}>
        <LoadingSpinner colorVariable="--primary-500" />
      </div>
    );
  }
}
