import jwtDecode from 'jwt-decode';
import { useCallback, useContext } from 'react';
import { IToken } from '../models/user';
import { ApplicationContext } from '../store/context';
import { useMagicToken } from './mutations/useMagicToken';

export interface IAuthFetch {
  endpoint: URL;
  params: Record<string, string>;
}

const useApi = () => {
  const {
    state: {
      user: { token },
    },
  } = useContext(ApplicationContext);
  const getToken = useMagicToken();

  const authFetch = useCallback(
    async <Response>({ endpoint, params }: IAuthFetch): Promise<Response> => {
      let auth = '';

      function hasValidToken() {
        if (!token) return false;
        const decodedToken = jwtDecode<IToken>(token);
        const tokenIsValid = decodedToken?.exp
          ? new Date(decodedToken?.exp * 1000) >= new Date()
          : false;
        return tokenIsValid;
      }

      if (hasValidToken()) {
        auth = token;
      } else {
        const data = await getToken.mutateAsync();
        if (data) {
          auth = data.JWT;
        }
      }

      const headers = params.headers ? params.headers : {};
      const response = await fetch(endpoint, {
        headers: {
          ...headers,
          Authorization: auth,
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        ...params,
      });
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error);
      }
      const data = await response.json();

      return data;
    },

    [token, getToken],
  );

  return {
    authFetch,
  };
};

export default useApi;
