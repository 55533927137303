import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  ApplicationStatus,
  IStatuses,
  StatusGroup,
} from '../../models/application-status';
import { ApplicationContext } from '../../store/context';
import useApi, { IAuthFetch } from '../useApi';
import { tokenKeys } from './cache-keys/tokenKeys';

const BASE_URL = process.env.REACT_APP_API_URL;

function statusesQuery(request: (params: IAuthFetch) => Promise<IStatuses>) {
  return getStatuses(request);
}

export function useStatuses() {
  const {
    state: {
      user: { token },
    },
  } = useContext(ApplicationContext);
  const { authFetch } = useApi();
  const cacheKey = tokenKeys.status({ token });

  return useQuery(cacheKey, () => statusesQuery(authFetch));
}

async function getStatuses(
  authFetch: (params: IAuthFetch) => Promise<IStatuses>,
): Promise<IStatuses> {
  const url = new URL('/loan-application/statuses/all', BASE_URL);
  try {
    const response = await authFetch({
      endpoint: url,
      params: {
        method: 'GET',
      },
    });
    return response;
  } catch (error) {
    console.log(error);
    throw new Error('Failed to fetch statuses');
  }
}

export function useStatusInGroup(
  group: StatusGroup,
  status?: ApplicationStatus,
) {
  const statuses = useStatuses();
  if (status && statuses.data) {
    return statuses.data[status].Groups.includes(group);
  } else {
    return false;
  }
}
