import styles from './StatusBar.module.scss';
import { useLoanApplication } from '../../hooks/queries/useLoanApplication';
import {
  ApplicationStatus,
  StatusGroup,
} from '../../models/application-status';
import { useLoanQuotes } from '../../hooks/queries/useLoanQuotes';
import { classNames } from '../../utils/classNames';
import { translation } from '../../utils/translations';
import { useStatusInGroup } from '../../hooks/queries/useStatuses';

export function StatusBar() {
  const loanApplication = useLoanApplication();
  const loanQuotes = useLoanQuotes();
  const status = loanApplication.data?.Status as ApplicationStatus;
  const isCancelled = useStatusInGroup(StatusGroup.Cancelled, status);
  const isAccepted = useStatusInGroup(StatusGroup.Accepted, status);
  const isDisbursed =
    status === ApplicationStatus.ApplicationStatusLoanDisbursed;
  const { t } = translation();

  const getStatusText = () => {
    if (
      loanQuotes.data?.AcceptableLoanQuotes &&
      status &&
      isAccepted !== undefined
    ) {
      if (
        isCancelled &&
        status !== ApplicationStatus.ApplicationStatusNotApprovedByAnyBank
      ) {
        return t('Unfortunately, your offer has been cancelled.');
      }
      if (isDisbursed) {
        return t(
          'Your loan has been disbursed and will appear in your account in a few days.',
        );
      }
      if (isAccepted) {
        return t(
          'You have made your selection and are awaiting for the loan to be paid out.',
        );
      }
      if (loanQuotes.data?.AllRegisteredLendersResponded) {
        if (loanQuotes.data?.AcceptableLoanQuotes.length === 0) {
          return t('All banks have responded.');
        }
        return t(
          'All of our bank partners have responded. Please make your selection below.',
        );
      }
      if (loanQuotes.data?.AcceptableLoanQuotes.length === 1) {
        return t('You received your first offer!');
      }
      return t('Please wait for all lenders to respond');
    }
    return t('Something went wrong');
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.infoBar,
          isAccepted && !isCancelled && styles.highlighted,
        )}
      >
        <span className={styles.infoText}>
          {loanQuotes.isLoading || loanApplication.isLoading
            ? t('Loading your offers, please wait')
            : getStatusText()}
        </span>
      </div>
      <div className={styles.statusBanner}>
        <span className={styles.idText}>
          {`${t('Your application ID is')}: ${
            loanApplication.data?.HumanReadableID
          }`}
        </span>
      </div>
    </div>
  );
}
