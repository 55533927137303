import { useContext } from 'react';
import { ApplicationContext } from '../../store/context';
import { useMutation, useQueryClient } from 'react-query';
import { applicationKeys } from '../queries/cache-keys/applicationKeys';
import { createToken } from './api/createToken';

const redirectUrl = 'https://www.axolaina.fi/';

export function useMagicToken() {
  const { dispatch } = useContext(ApplicationContext);
  const client = useQueryClient();

  const magicToken = new URLSearchParams(window.location.search).get('id');

  if (!magicToken) {
    window.location.href = redirectUrl;
  }

  return useMutation(() => createToken(magicToken), {
    mutationKey: ['magicToken'],
    retry: 2,
    onError: () => {
      window.location.href = redirectUrl;
    },
    onSuccess: (response) => {
      dispatch({
        type: 'Set user',
        scope: { parentType: 'user' },
        payload: { JWT: response.JWT, applicationID: response.ID },
      });
      client.setQueryData(
        applicationKeys.root({
          token: response.JWT,
          applicationID: response.ID,
        }),
        response,
      );
    },
  });
}
