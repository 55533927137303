import { QueryFunctionContext, useQuery } from 'react-query';
import { useContext } from 'react';
import { ApplicationContext } from '../../store/context';
import {
  applicationKeys,
  parseApplicationKey,
} from './cache-keys/applicationKeys';
import { IGetQuotesResponse } from '../../models/loan-quote';
import useApi, { IAuthFetch } from '../useApi';

function quotesQuery(
  authFetch: (params: IAuthFetch) => Promise<IGetQuotesResponse>,
  {
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof applicationKeys['quotes']>>,
) {
  const { applicationID } = parseApplicationKey(queryKey);
  return getLoanQuotes(authFetch, applicationID);
}

export function useLoanQuotes() {
  const {
    state: {
      user: { token },
      applicationID,
    },
  } = useContext(ApplicationContext);
  const { authFetch } = useApi();

  const cacheKey = applicationKeys.quotes({
    token,
    applicationID,
  });

  return useQuery(cacheKey, (queryKey) => quotesQuery(authFetch, queryKey));
}

const BASE_URL = process.env.REACT_APP_INTEGRATION_URL;

async function getLoanQuotes(
  authFetch: (params: IAuthFetch) => Promise<IGetQuotesResponse>,
  applicationID: string | undefined,
): Promise<IGetQuotesResponse> {
  const url = new URL(
    `/loan-quote-presentation/application/${applicationID}/quotes`,
    BASE_URL,
  );
  try {
    const response = await authFetch({
      endpoint: url,
      params: {
        method: 'GET',
      },
    });
    return response;
  } catch (error) {
    console.log(error);
    throw new Error('There was an error fetching your offers. ');
  }
}
