import { ILoanApplication } from '../../../models/loan-application';

const BASE_URL = process.env.REACT_APP_API_URL;

interface IMagicOutput extends ILoanApplication {
  JWT: string;
}
export async function createToken(
  magicToken: string | null,
): Promise<IMagicOutput> {
  const url = new URL(`/loan-application/magic/${magicToken}`, BASE_URL);
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch {
    throw new Error('Failed to fetch loan application');
  }
}
