import { tokenKeys, ITokenKeyParams } from './tokenKeys';

export interface IApplicationKeyParams extends ITokenKeyParams {
  applicationID: string;
}

export const applicationKeys = {
  root: (params: IApplicationKeyParams) =>
    [...tokenKeys.root(params), params.applicationID] as const,
  quotes: (params: IApplicationKeyParams) =>
    [...applicationKeys.root(params), 'quotes'] as const,
};

export function parseApplicationKey<T extends keyof typeof applicationKeys>(
  queryKey: ReturnType<typeof applicationKeys[T]>,
) {
  const [, token, applicationID] = queryKey;

  return { token, applicationID };
}
