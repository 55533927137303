export enum QueryErrorEvents {
  failedToFetch = 'fail',
}

export function subscribe(
  eventName: QueryErrorEvents,
  listener: (evt: CustomEventInit) => void,
) {
  document.addEventListener(eventName, listener);
}

export function unsubscribe(
  eventName: QueryErrorEvents,
  listener: (evt: CustomEventInit) => void,
) {
  document.removeEventListener(eventName, listener);
}

export function publish(eventName: QueryErrorEvents, eventData: unknown) {
  const event = new CustomEvent(eventName, { detail: eventData });
  document.dispatchEvent(event);
}
