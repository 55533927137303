import { Button, H1, H4, Icons } from '@a2755/ui-components';
import { useState } from 'react';
import { AcceptOfferModal } from '../../components/AcceptOfferModal';
import { ApprovalIndicator } from '../../components/ApprovalIndicator';
import { ChangeAcceptOfferModal } from '../../components/ChangeAcceptOfferModal';
import { LoadingGears } from '../../components/GearsLoader';
import {
  LoanQuoteListItem,
  QuoteState,
} from '../../components/LoanQuoteListItem';
import { NoOffers } from '../../components/NoOffers';
import { StatusBar } from '../../components/StatusBar';
import { useLoanApplication } from '../../hooks/queries/useLoanApplication';
import { useLoanQuotes } from '../../hooks/queries/useLoanQuotes';
import { useStatusInGroup } from '../../hooks/queries/useStatuses';
import { translation } from '../../utils/translations';
import {
  ApplicationStatus,
  StatusGroup,
} from '../../models/application-status';
import { IEnrichedLoanQuote, ILoanQuote } from '../../models/loan-quote';
import styles from './LoanQuoteList.module.scss';
import { LoanQuoteStatus } from '../../models/quote-status';
import { CreditScore } from '../../components/CreditScore';

export function LoanQuoteList() {
  const loanApplication = useLoanApplication();
  const marketCountry = loanApplication.data?.MarketCountry;
  const quotes = useLoanQuotes();
  const [acceptModalOffer, setAcceptModalOffer] =
    useState<IEnrichedLoanQuote | null>(null);
  const [changeSelectionOpen, setChangeSelectionOpen] = useState(false);
  const applicationDisbursing = useStatusInGroup(
    StatusGroup.Disbursing,
    loanApplication?.data?.Status as ApplicationStatus,
  );
  const { t } = translation();

  const acceptedQuote = quotes.data?.AcceptedLoanQuotes[0];

  const goToBank = (quote: ILoanQuote) => {
    window.open(quote.SigningLink);
  };

  return (
    <>
      <div className={styles.container}>
        <H1 className={styles.title}>{t('Welcome!')}</H1>
        <StatusBar />
        {quotes.isLoading ? (
          <LoadingGears text={t('Loading your offers')} />
        ) : (
          quotes.data && (
            <>
              {(acceptedQuote ||
                quotes.data.AcceptableLoanQuotes.length > 0) && (
                <H4 className={styles.subHeader}>{t('Your offers')}</H4>
              )}
              {acceptedQuote && (
                <>
                  <LoanQuoteListItem
                    quote={acceptedQuote}
                    state={
                      acceptedQuote.Status ===
                      LoanQuoteStatus.LoanApprovedForDisbursementDisbursedByBank
                        ? QuoteState.Disbursed
                        : QuoteState.Selected
                    }
                    action={() => goToBank(acceptedQuote)}
                  />
                  {!applicationDisbursing &&
                    (quotes.data.AcceptableLoanQuotes.length > 0 ||
                      quotes.data.AcceptedLoanQuotes.length > 1) && (
                      <div className={styles.acceptedButtons}>
                        <Button
                          variant="white"
                          onClick={() =>
                            setChangeSelectionOpen((prevState) => !prevState)
                          }
                          classes={{ root: styles.link }}
                          endIcon={
                            <Icons.Chevron
                              size="xs"
                              classes={{ root: styles.chevron }}
                              color={{ primary: '--dark-500' }}
                            />
                          }
                        >
                          {t('Change my selection')}
                        </Button>
                      </div>
                    )}
                </>
              )}
              {(!acceptedQuote || changeSelectionOpen) && (
                <>
                  {quotes.data.AcceptedLoanQuotes.filter(
                    (quote, index) => index > 0,
                  ).map((quote) => (
                    <LoanQuoteListItem
                      key={quote.ID}
                      quote={quote}
                      state={QuoteState.PreviouslySelected}
                      action={() => goToBank(quote)}
                    />
                  ))}
                  {quotes.data.AcceptableLoanQuotes.map((quote) => (
                    <LoanQuoteListItem
                      key={quote.ID}
                      quote={quote}
                      state={
                        quote.Recommended
                          ? QuoteState.Recommended
                          : QuoteState.None
                      }
                      action={() => setAcceptModalOffer(quote)}
                    />
                  ))}
                </>
              )}
              {!acceptedQuote &&
                (quotes.data.AllRegisteredLendersResponded ? (
                  quotes.data.AcceptableLoanQuotes.length === 0 ? (
                    <NoOffers />
                  ) : (
                    <ApprovalIndicator
                      numberOfOffers={quotes.data.AcceptableLoanQuotes.length}
                    />
                  )
                ) : (
                  <LoadingGears
                    text={t('As soon as you recieve more offers')}
                  />
                ))}
              {applicationDisbursing && (
                <CreditScore
                  text={t(
                    "Discover your credit score for free and in less than 2 minutes - don't wait any longer to take control of your finances.",
                  )}
                />
              )}
            </>
          )
        )}
      </div>
      {marketCountry &&
        acceptModalOffer &&
        (acceptedQuote ? (
          <ChangeAcceptOfferModal
            oldOffer={acceptedQuote}
            newOffer={acceptModalOffer}
            marketCountry={marketCountry}
            closeModal={() => setAcceptModalOffer(null)}
          />
        ) : (
          <AcceptOfferModal
            offer={acceptModalOffer}
            marketCountry={marketCountry}
            closeModal={() => setAcceptModalOffer(null)}
          />
        ))}
    </>
  );
}
