export type IStatuses = {
  [key in ApplicationStatus]: {
    Code: number;
    Name: string;
    Description: string;
    Groups: (keyof typeof StatusGroup)[];
  };
};

export enum StatusGroup {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Registered = 'Registered',
  Accepted = 'Accepted',
  Disbursing = 'Disbursing',
}

export enum ApplicationStatus {
  ApplicationStatusStarted = '100 Started',
  ApplicationStatusPendingReceiptCustomerInputComplete = '160 Pending receipt. Customer input complete',
  ApplicationStatusPendingReceiptFetchingExternalData = '180 Pending receipt. Fetching external data',
  ApplicationStatusWithdrawnByCustomerIncomplete = '190 Withdrawn by customer',
  ApplicationStatusCompleted = '200 Completed',
  ApplicationStatusApplicationReviewManualAdjustment = '201 Application review. Manual adjustment',
  ApplicationStatusApplicationReviewAutomaticAdjustment = '209 Application review. Automatic adjustment',
  ApplicationStatusPendingRegistrationWithBanks = '210 Pending registration with banks',
  ApplicationStatusNotRegisteredWithAnyBank = '270 Not Registered with Any Bank',
  ApplicationStatusCompletedCancelledByAxo = '280 Cancelled By Axo',
  ApplicationStatusCompletedWithdrawnByCustomer = '290 Withdrawn By Customer',
  ApplicationStatusRegistered = '300 Registered',
  ApplicationStatusPendingBankApproval = '310 Pending bank approval',
  ApplicationStatusNotApprovedByAnyBank = '370 Not Approved by Any Bank',
  ApplicationStatusRegisteredCancelledByAxo = '380 Cancelled By Axo',
  ApplicationStatusRegisteredWithdrawnByCustomer = '390 Withdrawn By Customer',
  ApplicationStatusOfferApproved = '400 Offer approved',
  ApplicationStatusPendingCustomerAccept = '410 Pending customer accept',
  ApplicationStatusNotAcceptedByCustomer = '470 Not accepted by customer',
  ApplicationStatusApprovedCancelledByAxo = '480 Cancelled By Axo',
  ApplicationStatusApprovedWithdrawnByCustomer = '490 Withdrawn By Customer',
  ApplicationStatusOfferAccepted = '500 Offer accepted',
  ApplicationStatusPendingBankApprovalForDisbursement = '510 Pending bank approval for disbursement',
  ApplicationStatusNotApprovedForDisbursementByAnyBank = '570 Not approved for disbursement by any bank',
  ApplicationStatusOfferAcceptedCancelledByAxo = '580 Cancelled by Axo',
  ApplicationStatusOfferAcceptedWithdrawnByCustomer = '590 Withdrawn by customer',
  ApplicationStatusLoanApprovedForDisbursement = '600 Loan approved for disbursement',
  ApplicationStatusPendingDisbursement = '610 Pending disbursement',
  ApplicationStatusNotDisbursedByAnyBank = '670 Not disbursed by any bank',
  ApplicationStatusDisbursementCancelledByAxo = '680 Disbursement cancelled by Axo',
  ApplicationStatusDisbursementWithdrawnByCustomer = '690 Disbursement withdrawn by customer',
  ApplicationStatusLoanDisbursed = '700 Loan disbursed',
}
