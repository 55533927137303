import { FAQ, IQuestion } from '@a2755/ui-components';
import { translation } from '../../utils/translations';

export const FAQElement = () => {
  const { t } = translation();
  const elements: IQuestion[] = [
    {
      _id: 'faq-question-1',
      question: t('FAQ Question 1'),
      answer: t('FAQ Answer 1'),
    },
    {
      _id: 'faq-question-2',
      question: t('FAQ Question 2'),
      answer: t('FAQ Answer 2'),
    },
    {
      _id: 'faq-question-3',
      question: t('FAQ Question 3'),
      answer: t('FAQ Answer 3'),
    },
  ];

  return (
    <FAQ
      _id={'faq-element-id'}
      faq={elements}
      heading={t('Frequently asked questions')}
    />
  );
};
