import { useLoanApplication } from '../../hooks/queries/useLoanApplication';
import { useStatusInGroup } from '../../hooks/queries/useStatuses';
import {
  ApplicationStatus,
  StatusGroup,
} from '../../models/application-status';
import { translation } from '../../utils/translations';
import { FAQElement } from '../FAQElement';
import styles from './NoOffers.module.scss';
import { CreditScore } from '../CreditScore';

export const NoOffers = () => {
  const { t } = translation();
  const loanApplication = useLoanApplication();
  const status = loanApplication.data?.Status as ApplicationStatus;
  const offerCancelled = useStatusInGroup(StatusGroup.Cancelled, status);

  return (
    <div className={styles.container}>
      <span>
        <p>{t('Thank you for you application!')}</p>
        {offerCancelled &&
        status !== ApplicationStatus.ApplicationStatusNotApprovedByAnyBank ? (
          <p>
            {t(
              'All our partners have now processed your loan application and unfortunately the offers have been declined.',
            )}
          </p>
        ) : (
          <p>
            {t(
              'Our lenders have processed you application, but unfortunately, did not return an offer.',
            )}
          </p>
        )}
      </span>
      <CreditScore
        text={t(
          'Please consult the guides below on how to improve your credit score',
        )}
      />
      <FAQElement />
    </div>
  );
};
