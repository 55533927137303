import { QueryFunctionContext, useQuery } from 'react-query';
import {
  parseApplicationKey,
  applicationKeys,
} from './cache-keys/applicationKeys';
import { ILoanApplication } from '../../models/loan-application';
import { ApplicationContext } from '../../store/context';
import { useContext } from 'react';
import useApi, { IAuthFetch } from '../useApi';

function loanApplicationQuery(
  authFetch: (params: IAuthFetch) => Promise<ILoanApplication>,
  {
    queryKey,
  }: QueryFunctionContext<ReturnType<typeof applicationKeys['root']>>,
) {
  const { applicationID } = parseApplicationKey(queryKey);
  return getLoanApplication(authFetch, applicationID);
}

export function useLoanApplication() {
  const {
    state: {
      user: { token },
      applicationID,
    },
  } = useContext(ApplicationContext);
  const { authFetch } = useApi();

  const cacheKey = applicationKeys.root({
    token,
    applicationID,
  });

  return useQuery(
    cacheKey,
    (queryKey) => loanApplicationQuery(authFetch, queryKey),
    { enabled: !!applicationID },
  );
}

const BASE_URL = process.env.REACT_APP_API_URL;

async function getLoanApplication(
  authFetch: (params: IAuthFetch) => Promise<ILoanApplication>,
  id: string,
): Promise<ILoanApplication> {
  const url = new URL(`/loan-application/${id}`, BASE_URL);
  try {
    const response = await authFetch({
      endpoint: url,
      params: {
        method: 'GET',
      },
    });
    return response;
  } catch (error) {
    console.log(error);
    throw new Error('Failed to fetch loan application');
  }
}
